.google-signin-button {
    text-align: center;
    background-color: var(--clr-accent-main);
    border-radius: 5px;
    display: flex;
    color: #fff;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 50px;
    width: 100%;
    padding: 10px 15px;
    position: relative;
    transform: scale(1);
    border: none;
    transition: background 0.2s ease, color 0.2s ease, transform 0.2s ease;
    z-index: 1;
    font-size: 1.2rem;
    font-family: Lato, sans-serif;
}

.google-signin-button svg {
    font-size: 1.5rem;
    color: #fff;
    fill: #fff;
    transition: fill 0.2s ease-in-out;
}

.google-signin-button:hover{
    cursor: pointer;
    background-color: #fff;
    color: var(--clr-accent-main);
    transform: scale(0.95);
}

.google-signin-button:hover > svg {
    fill: var(--clr-accent-main);
}