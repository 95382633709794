.main-content {
    position: relative;
    background: linear-gradient(45deg, var(--clr-accent-main) 0%, var(--clr-accent-secondary) 100%);
    justify-content: center;
    padding-inline: 15px;
    min-height: 100%;
    color: var(--clr-dark-bg);
    font-family: var(--font-main);
    margin-top: calc(var(--nav-height) * 1.5);
    margin-bottom: calc(var(--nav-height) * 2);
    margin-inline: 10px;
    padding-top: calc(var(--nav-height) / 2.5);
    padding-bottom: calc(var(--nav-height));
    /* gap: 25px; */
    z-index: 2;
    border-radius: 50px;
}

h1 {
    font-family: var(--font-main);
    text-transform: uppercase;
    font-weight: 900 !important;
}

.content-greeting {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-left: 10px;
}

.content-greeting-pre {
    font-size: 2rem;
    font-weight: 500;
    color: var(--clr-dark-bg);
}

.content-greeting-name {
    font-size: 2rem;
    font-weight: 900;
    color: var(--clr-dark-bg);
}

.content-header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 85px;
    margin-bottom: 10px;
    padding-block: 5px 15px;
    z-index: 1;
}

.content-header-bg {
    position: absolute;
    top: 0;
    border: none;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg,rgba(255, 255, 255, 0) 0%, #ac313166 50%, rgba(0, 0, 0, 0) 95%);
    z-index: -2;
}

.content-header-text {
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--clr-dark-bg);
}