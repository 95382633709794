.brand-logo {
    color: #fff;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: var(--font-secondary);
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 900;
    justify-content: center;
    gap: 5px;
}

.brand-logo span {
    font-size: 2rem;
}

.nav-icon {
    height: 50px;
    width: 50px;
}

.navbar-main {
    position: fixed;
    top: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-dark-bg);
    padding: 5px 25px;
    min-height: var(--nav-height);
    max-height: var(--nav-height);
    z-index: 3;
    /* gap: 25px; */
}

.navbar-main:first-child {
    content: '';
    width: 100%;
    height: 100%;
}

.logout-button {
    display: flex;
    flex-direction: row;
    color: #bf4040;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
}

.logout-button span {
    font-size: 1.5rem;
}

.logout-button:hover {
    color: var(--clr-accent-secondary);
    cursor: pointer;
}