.nom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.sortByRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 5px;
    margin-inline: 10px;
}

.sortBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.sortLabel {
    font-size: 1rem;
    text-align: right;
    line-height: 1;
    font-weight: 900;
    color: var(--clr-dark-bg);
    user-select: none;
}

#sortBy {
    font-size: 1.1rem;
    font-weight: 900;
    font-family: var(--font-main);
    color: var(--clr-dark-bg);
    border: 1px solid var(--clr-dark-bg);
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    background-color: #ffffff00;
    transition: background-color 0.2s ease;
}

.sortByOption {
    font-family: var(--font-main);
}

#sortBy:hover {
    background-color: #ffffffff;
    cursor: pointer;
}

.filterInput {
    font-size: 1.1rem;
    font-weight: 900;
    color: var(--clr-dark-bg);
    border: 1px solid var(--clr-dark-bg);
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    background-color: #ffffff00;
    transition: background-color 0.2s ease;
    outline: 2px solid #ffffff00;
    transition: outline 0.2s ease, background-color 0.2s ease;
    font-family: var(--font-main);
}

.filterInput:focus {
    outline: 2px solid var(--clr-accent-main);
    background-color: #ffffff55;
}

.newNomRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 5px;
    width: 100%;
}

.newNomLabel {
    font-size: 1.25rem;
    text-align: right;
    line-height: 1;
    font-weight: 900;
    color: var(--clr-dark-bg);
    user-select: none;
}

.newNomPlus {
    display: grid;
    place-items: center;
    background-color: #ffffff2e;
    font-size: 2.5rem;
    font-weight: 900;
    color: #ffffff9c;
    border: 2px solid #ffffff35;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    height: 75px;
    text-align: center;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.newNomPlus:hover {
    background-color: #ffffff55;
    color: var(--clr-dark-bg);
    cursor: pointer;
}