.footer-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;;
    background: linear-gradient(180deg,#ffffff00 0%, var(--clr-dark-bg-semi-trans) 50%);
    padding: 15px 20px;
    text-align: center;
    min-height: var(--nav-height);
    position: fixed;
    bottom: 0;
    width: 100%;
    border-radius: 10px 10px 0 0;
    margin-top: var(--nav-height);
    gap: 5px;
    z-index: 2;
}

.footer-button {
    color: var(--clr-dark-bg);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    user-select: none;
    width: max-content;
    width: 100%;
    background: linear-gradient(0deg, #f36b50,var(--clr-accent-secondary) );
    /* background-color: #ffa54b; */
    border: none;
    border-radius: 10px;
    padding: 5px;
    opacity: 1;
    transform: scale(1);
    transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.footer-button:hover {
    /* color: var(--clr-accent-third-dark); */
    transform: scale(0.95);
    opacity: 0.8;
    /* background: linear-gradient(120deg, var(--clr-accent-third), var(--clr-accent-third-dark)); */
    cursor: pointer;
}

.footer-button span {
    font-size: 2.2rem;
}

.footer-button .material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48 !important;
}