/* === NEW NOM CONTENT === */
.new-nom-content {
    height: 100%;
    width: 100%;
    padding-inline: 5px;
}
/* === */

/* === NEW NOM HEADER === */
.new-nom-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: var(--font-main);
    font-size: 1.5rem;
}
/* === */

/* === NEW NOM BODY === */
.new-nom-body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: var(--font-main);
    width: 90%;
    gap: 10px;
    margin-inline: auto;
    padding-bottom: 5px;
}

.new-nom-body-row {
    width: 100%;
}
/* === */

/* === HORIZONTAL RULER === */
.new-nom-hr {
    border: 2px dashed #9c9c9c50;
    border-style: none none dashed; 
    color: #fff; 
    background-color: #fff;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-block: 10px;
}
/* === */

/* === NEW NOM BODY ROW INPUT RADIO === */
.new-nom-body-row-input-radio {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    align-content: space-between;
    gap: 5px;
}

.new-nom-body-row-input-radio label {
    margin-left: 3px;
    margin-right: 15px;
}
/* === */

/* === NEW NOM BODY ROW LABEL === */
.new-nom-body-row-label {
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-weight: 600;
}
/* === */

/* === NEW NOM TEXT INPUT === */
.new-nom-text-input {
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    background: var(--clr-white-off);
    color: var(--clr-dark-bg);
    font-size: 1rem;
    font-family: Lato, sans-serif;
    outline: 5px solid transparent;
    transition: outline 0.1s ease-in-out;
}

.new-nom-text-input::placeholder {
    color: var(--clr-text-gray-med);
}

.new-nom-text-input:focus-visible {
    outline: 3px solid var(--clr-accent-main) !important;
}
/* === */

/* === NEW NOM RADIO BUTTON === */
.new-nom-radio-button {
    width: 1.5rem;
    height: 1.5rem;
    accent-color: var(--clr-accent-main);
    margin-block: 5px;
    margin-right: 2px;
}
/* === */

/* === NEW NOM TIME INPUT === */
.new-nom-time-input {
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    background: var(--clr-white-off);
    color: var(--clr-dark-bg);
    font-size: 1rem;
    font-family: Lato, sans-serif;
    outline: 5px solid transparent;
    transition: outline 0.1s ease-in-out;
}
/* === */

/* === NEW NOM FOOTER === */
.new-nom-footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 50px;
}
/* === */

/* === NEW NOM RADIO ICON === */
.new-nom-radio-icon {
    user-select: none;
    margin-left: 2px;
    width: 1.5rem;
    height: 1.5rem;
    accent-color: var(--clr-accent-main);
}
/* === */

/* === NEW NOM SUGGESTED NOM === */
.new-nom-suggestions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* padding-inline: 5px; */
    gap: 5px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
}
.new-nom-suggestion {
    font-family: var(--font-main);
    font-size: 1.1rem;
    color: var(--clr-gray-light-semi-trans);
    border: 2px solid var(--clr-gray-light-semi-trans);
    border-radius: 5px;
    padding: 5px;
    width: 48%;
    word-wrap: break-word;
    transform: scale(1);
    transition: color 0.2s ease, border 0.2s ease, transform 0.2s ease;
}

.new-nom-suggestion:hover {
    border: 2px solid var(--clr-accent-main);
    cursor: pointer;
    color: var(--clr-accent-main);
    transform:scale(0.95);
}
/* === */

.nom-plus {
    display: grid;
    place-items: center;
    /* background-color: var(--clr-accent-main); */
    border: 1px solid var(--clr-gray-light-semi-trans);
    border-radius: 100px;
    padding: 1px;
    color: var(--clr-gray-light-semi-trans);
    margin-left: auto;
    transition: color 0.2s ease, border 0.2s ease;
}

.new-nom-suggestion:hover .nom-plus {
    /* Add your desired hover styles for .nom-plus here */
    border: 1px solid var(--clr-accent-main); /* As an example */
    color: var(--clr-accent-main);
}