.main-profile-content {
    position: relative;
    background: linear-gradient(45deg, var(--clr-accent-main) 0%, var(--clr-accent-secondary) 100%);
    padding-inline: 25px;
    min-height: 100%;
    color: var(--clr-dark-bg);
    font-family: Lato, sans-serif;
    margin-top: var(--nav-height);
    padding-top: calc(var(--nav-height) / 4);
    padding-bottom: calc(var(--nav-height) * 2);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 20px;
}

.profile-bubble {
    position: relative;
    background-color: #ffffff68;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
}

.profile-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    min-height: 100%;
    flex-grow: 1;
}

.profile-header-bubble {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap: 10px;
}

.profile-header-bubble h1 {
    text-align: right;
}

.profile-feeling-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.profile-user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}