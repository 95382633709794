
/* NOM BODY */
.nom-main {
    background-color: #ffffff68;
    border-radius: 10px;
    padding: 15px 15px;
    width: 100%;
}
/* === */

.not-updated {
    outline: 3px dashed var(-clr-nom-bad);
    outline-offset: 1px;
    box-shadow: 0 0 15px var(--clr-nom-bad);
    margin-block: 3px;
}


/* NOM HEADER */
.nom-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.nom-header h1{
    font-size: 1rem;
}
/* === */

/* TITLE AND TIME */
.nom-base-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* padding-inline: 5px; */
    gap: 5px;
}

.nom-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    min-height: 2rem;
    padding: 5px 8px;
    border-radius: 5px;
    width: 100%;
    background: linear-gradient(150deg, #ffffff68 0%, #ffffff68 10%, #ffffff00 75%);
    font-size: 0.4rem;
}

.nom-title span {
    user-select: none;
}

.nom-type-icon {
    aspect-ratio: 1/1;
    /* width: 100%; */
    max-width: 2rem;
    /* background-color: #ffffff68; */
    border-radius: 5px;
    /* padding: 2px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* transition: background-color 0.2s ease, color 0.2s ease; */
    user-select: none;
}

.nom-type-icon span {
    font-size: 1rem;
    /* font-weight: 100 !important; */
    user-select: none;
}

.nom-type-icon-food {
    /* color: var(--clr-accent-main) !important; */
    color: var(--clr-dark-bg);
    font-weight: 100 !important;
}

.nom-type-icon-drink {
    /* color: var(--clr-accent-third-dark) !important; */
    color: var(--clr-dark-bg);
    font-weight: 100 !important;
}

.nom-type-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    flex-grow: 1;
}

.nom-amount {
    aspect-ratio: 1/1;
    width: 100%;
    max-width: 2rem;
    background-color: #ffffff68;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.nom-amount span {
    font-size: 0.75rem;
    font-weight: 600;
}

.nom-time-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    /* border: 1px solid var(--clr-main-dark); */
    padding: 5px 8px;
    border-radius: 5px;
    min-height: 2rem;
    color: #575757b4;
    min-width: max-content;
    /* opacity: 0.25; */
}

.nom-time-info span {
    font-size: 1.25rem;
    font-weight: 600;
    color: #575757b4;
    user-select: none;
}
/* === */

/* SEPARATOR HR LINE */
.nom-separator {
    height: 4px;
    border: none;
    outline: none;
    background: linear-gradient(40deg, rgba(255,255,255,0.4) 0%, rgba(0,0,0,0) 95%);
    border-radius: 30px;
    margin-block: 10px;
}
/* === */

/* NOM BODY */

.nom-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

/* === */

/* NOM FEELING TEXT AND ICON */

.nom-feelings {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}
.nom-feeling {
    min-height: 40px;
    max-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 5px 8px;
    font-weight: 600;
    background-color: #ffffff68;
    /* transform: rotateZ(-4deg); */
}

.nom-feeling-bad > * {
    color: var(--clr-nom-bad) !important;
}

.nom-feeling-meh > * {
    color: var(--clr-nom-meh) !important;
}

.nom-feeling-good > *{
    color: var(--clr-nom-good) !important;
}

.nom-feeling-great > * {
    color: var(--clr-nom-great) !important;
}

.nom-feeling-secondary {
    background-color: rgba(255, 255, 255, 0.178) !important;
}

.nom-feeling-secondary > * {
    color: #3f3f3f8c !important;
}

.nom-feeling-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: var(--clr-dark-bg);
    /* opacity: 0.75; */
}

.nom-feeling-icon span {
    font-weight: 400;
}
/* === */

/* NOM ACTION BUTTONS */
.nom-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.nom-icon {
    height: 2rem;
    width: 2rem;
    /* background-color: #ffffff68; */
    border-radius: 5px;
    padding: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease, color 0.2s ease;
}
.nom-delete-icon {
    color: var(--clr-accent-main);
}

.nom-edit-icon {
    color: var(--clr-accent-third-dark);
}
.nom-icon span {
    font-size: 1.75rem;
}

.nom-icon:hover {
    color: #000;
    background-color: #ffffff;
    cursor: pointer;
}
/* === */


/* CHANGES OUTLINE ON CLOCK ICON */
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48 !important;
}

