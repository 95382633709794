.login-screen {
    position: relative;
    background: linear-gradient(45deg, var(--clr-accent-main) 0%, var(--clr-accent-secondary) 100%);
    padding: 10px 25px;
    height: 100vh !important;
    color: var(--clr-dark-bg);
    font-family: var(--font-main);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 25px; */
}

.login-logo {
    color: #fff;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-family: var(--font-secondary);
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.login-logo img {
    width:75px;
    height:75px;
}

.login-form .separator-hr {
    width: 100%;
    height: 10px;
    border-radius: 30px;
    background: linear-gradient(45deg, var(--clr-accent-main) 0%, var(--clr-accent-secondary) 100%);
    border: none;
    margin-block: 15px;
}

.login-box {
    position: relative;
    background: rgba(255,255,255,0.5);
    border-radius: 10px;
    padding: 10px 25px;
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    color: var(--clr-dark-bg);
    padding-block: 25px;
}

.login-title {
    font-size: 2rem;
    font-family: var(--font-main);
    color: var(--clr-accent-main);
    margin-bottom: 15px;
}

.login-inputs {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.login-input {
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    background: var(--clr-accent-main-light);
    color: var(--clr-dark-bg);
    /* placeholder text color */
    font-size: 1.2rem;
    font-family: var(--font-main);
    outline: 5px solid transparent;
    transition: outline 0.1s ease-in-out;
}

.login-input::placeholder {
    color: var(--clr-text-gray-med);
}

.login-input:focus-visible {
    outline: 5px solid var(--clr-accent-main) !important;
}

.forgot-password a {
    /* color: var(--clr-accent-main); */
    text-decoration: none;
}

.forgot-password a:hover {
    /* color: var(--clr-dark-bg); */
}

.login-button:hover {
    background: #fff;
    color: var(--clr-accent-main);
}

.register-link {
    background: none;
    border: none;
    font-size: 1rem;
    color: var(--clr-accent-main);
    text-decoration: none;
    cursor: pointer;
    font-family: var(--font-main);
}

.register-link:hover {
    color: var(--clr-dark-bg);
}

.forgot-password-link {
    cursor: not-allowed;
    color: gray;
}

.forgot-password-link {
    cursor: not-allowed;
}