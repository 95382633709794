.main-initial-info-content {
    position: relative;
    background: linear-gradient(45deg, var(--clr-accent-main) 0%, var(--clr-accent-secondary) 100%);
    padding-inline: 25px;
    min-height: 100%;
    color: var(--clr-dark-bg);
    font-family: Lato, sans-serif;
    margin-top: var(--nav-height);
    padding-top: calc(var(--nav-height) / 4);
    padding-bottom: calc(var(--nav-height) * 2);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 20px;
}