/* === CHECKBOX SELECTOR CONTENT === */
.checkbox-selector-row {
    width: 100%;
    /* margin-inline: auto; */
}

.checkbox-selector-row-label {
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-weight: 600;
}

.checkbox-selector-row-input {
    display: grid;
    grid-template-columns: auto auto;  /* Each item will now be as wide as its content */
    justify-content: center;  /* Centers the entire grid horizontally */
    align-items: center;
    gap: 10px;
}

.checkbox-selector-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px;
    padding: 5px 8px;
    font-weight: 600;
    width: 150px;
    background-color: #00000035;
    color: #000;
    transition: background-color 0.3s, transform 0.2s;
    transform: scale(1);
}

.checkbox-selector-option label {
    margin-left: 5px;
    user-select: none;
}

.checkbox-selector-option:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.checkbox-selector-option label {
    cursor: pointer;
}

.checkbox-selector-option.selected {
    background-color: var(--clr-nom-ailment);
    color: #fff;
}

.checkbox-selector-input {
    width: 1.5rem;
    height: 1.5rem;
    accent-color: var(--clr-accent-secondary);
    margin-block: 5px;
    margin-right: 2px;
}

/* === */
