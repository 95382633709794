* {
    --icon-size: 65px;
    --icon-half: calc(var(--icon-size) / 2);
}

.modal-inactive {
    display: none !important;
}

.modal {
    position: fixed;
    z-index: 5;
    /* padding-top: 200px; */
    padding-top: 30px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
}

.modal-header {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.modal-bg {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000059;
}

.modal-icon-container {
    position: relative;
}

.modal-icon {
    position: absolute;
    top: -30px;
    right: calc( 50% - var(--icon-half)); /*(modal width / 2 - icon width / 2 */
    width: var(--icon-size);
    height: var(--icon-size);
    border-radius: 100%;
    /* background-color: var(--clr-accent-third); */
    background: linear-gradient(-40deg, var(--clr-accent-main) 0%, var(--clr-accent-secondary) 100%);
    border: 2px solid white;
    box-shadow: 0 0 1px 1px #00000070;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.modal-icon span {
    font-size: 2.5rem;
    color: #fff;
    /* border: 3px solid #fff; */
    /* border-radius: 100px; */
    /* padding: 4px; */
    user-select: none;
    /* background-color: #444444; */
    /* transform: rotateZ(-45deg); */
}

.modal-content {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    padding-top: 45px;
    margin: 25px;
    /* height: 300px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 10px 2px #0000004f;
    /* align-items: center; */
    /* transform: translateY(0); */
    /* animation-name: animatetop;
    animation-duration: 0.75s; */
    position: relative;
}

/* Add animation (fade in the background) */
.modal-bg {
    animation-name: fadeIn;
    animation-duration: 0.75s;
}

.modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: var(--clr-gray-light-semi-trans);
    /* background-color: #ffffff00; */
    border: none;
    outline: none;
    background-color: #ffffff00;
    border: 2px solid var(--clr-gray-light-semi-trans);
    border-radius: 10px;
    padding: 5px;
}

.modal-close:hover {
    /* background-color: var(--clr-dark-bg-semi-trans); */
    border-color: var(--clr-accent-main);
    color: var(--clr-accent-main);
    cursor: pointer;
}

.modal-close span {
    font-size: 2rem;
}

@keyframes animatetop {
    from {
        transform: translateY(-300px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Fade in background */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    gap: 10px;
    /* margin-inline: auto; */
}