.pagination-main {
    /* background-color: red; */
    overflow: hidden;
}

.pagination-display {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}

.pagination-object {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.pagination-page {
    width: 25px;
    height: 25px;
}

.pagination-page:hover {
    cursor: pointer;
    background-color: var(--clr-accent-third);
}