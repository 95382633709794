* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    --clr-accent-main: #d74f6fff;
    --clr-accent-main-transparent: #d74f6f00;
    --clr-accent-main-light: #e4afa5;
    --clr-accent-secondary: #f8c445ff;
    --clr-accent-secondary-transparent: #f8c44500;
    --clr-accent-third: #5dc69c;
    --clr-accent-third-dark: #459373;
    --clr-accent-third-transparent: #5dc69c00;
    --clr-main-dark: #474747;
    --clr-nom-ailment: #b64ec8;

    --clr-nom-great: var(--clr-accent-third-dark);
    --clr-nom-good: var(--clr-accent-main);
    --clr-nom-meh: #6f4f7c;
    --clr-nom-bad: #b62a4d;
    --clr-nom-terrible: #171717;
    --clr-nom-food: #f24040;
    --clr-nom-drink: #4b57d9;
    /* --clr-accent-main: #5d5bde; */
    /* --clr-accent-main: #1da56b; */
    --clr-dark-bg: #171717ff;
    --clr-dark-bg-semi-trans: #171717d4;
    --clr-gray-light: #626262ff;
    --clr-gray-light-semi-trans: #9e9c9cbc;
    --clr-text-gray-med: #5b5b5b;
    --clr-white-off: #ffd5b8ff;
    --nav-height: 60px;

    --font-main: "Lato", sans-serif;
    --font-secondary: "Rowdies", sans-serif;
}

.App {
    /* height: calc(100vh - calc(var(--nav-height) * 2)); */
    height: 100vh;
    overflow-x: hidden;
    background-color: var(--clr-dark-bg);
}