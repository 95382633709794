/* OR text */

.login-or {
    margin-top: 8px;
    margin-bottom: 10px;
}

.login-or-banner {
    position: relative;
    min-height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.login-hr {
    /* 1: light, 2: dark, 3: light, 4: light, 5: dark, 6: light */
    background: linear-gradient(90deg, var(--clr-accent-main-transparent) 0%, var(--clr-accent-main-transparent) 5%, var(--clr-accent-main) 25%, var(--clr-accent-main-transparent) 43%, var(--clr-accent-main-transparent) 57%, var(--clr-accent-main) 75%, var(--clr-accent-main-transparent) 95%, var(--clr-accent-main-transparent) 100%);
    border-radius: 30px;
    border: none;
    height: 5px;
    width: 100%;
}

.login-or-text {
    text-align: center;
    position: absolute;
    margin-inline: auto;
    width: 100%;
    line-height: 1;
    font-size: 1.25rem;
    font-family: Lato, sans-serif;
    font-weight: 900;
    color: var(--clr-accent-main);
}