.day-carousel-arrow {
    margin-inline: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    color: var(--clr-dark-bg);
    border-radius: 10px;
    font-size: 1.5rem;
    font-weight: 600;
    transition: background 0.2s ease-in-out, opacity 0.2s ease-in-out;
    background-color: var(--clr-white-off);
    opacity: 0.25;
    user-select: none;
}

.day-carousel-arrow:hover {
    cursor: pointer;
    background-color: var(--clr-white-off);
    opacity: 1;
}
