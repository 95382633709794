.nom-action-button {
    display: flex;
    flex-direction: row;
    font-size: 0.9rem;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 10px 10px;
    font-weight: 600;
    background-color: #ffffff68;
    font-family: var(--font-main);
    transform: scale(1);
    color: #fff;
    min-height: 40px;
    max-height: 40px;
    /* transform: rotateZ(-4deg); */
    border: none;
    transition: background-color 0.2s ease, color 0.2s ease, transform 0.2s ease;
}

.nom-action-button .material-symbols-outlined {
    margin-right: 5px;
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24 !important;
}

.nom-action-button:hover {
    background: var(--clr-accent-third-dark);
    cursor: pointer;
    color: #ffffff;
    transform: scale(0.95);
}

.nom-add-feeling { 
    background-color: var(--clr-nom-bad);
    color: var(--clr-white-off);
}