.day-carousel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* background-color: hotpink; */
    margin-block: 5px;
    padding-block: 5px;
    gap: 5px;
    padding-inline: 5px;
    overflow-x: show;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    margin-bottom: 1rem;
}

.day-carousel::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.day-carousel-item {
    color: var(--clr-accent-main);
    border-radius: 40px;
    flex: 1 0 auto;
    transition: background 0.2s ease-in-out;
}

.day-carousel-item:hover {
    cursor: pointer;
    background-color: var(--clr-white-off);
    /* color: var(--clr-text-gray-med); */
}

.day-carousel-item-today {
    background-color: var(--clr-dark-bg) !important;
    color: #fff !important;
}

.day-carousel-item-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    gap: 5px;
}

.day-carousel-item-date h1 {
    font-family: 'Lato', sans-serif;
    font-size: 0.8rem;
    font-weight: 600 !important;
    /* color: var(--clr-accent-secondary); */
}

.day-carousel-item-date span {
    color: var(--clr-dark-bg);
    font-size: 1rem;
    font-weight: 600;
}

.day-carousel-item-today-text span {
    color: var(--clr-accent-secondary);
    font-size: 1rem;
    font-weight: 600;
}

.greyed-out {
    color: #fff !important;
    background-color: #ffffff00 !important;
    opacity: 0.25;
    /* user-select: none !important; */
    cursor: default !important;
}