.action-button {
    margin-block: 10px;
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    background: var(--clr-accent-main);
    box-shadow: 0 0 10px #ad424240;
    color: #fff;
    font-size: 1.2rem;
    font-family: var(--font-main);
    transform: scale(1);
    outline: none;
    cursor: pointer;
    transition: background 0.2s ease, color 0.2s ease, transform 0.2s ease;
}

.action-button:hover {
    background: var(--clr-accent-third);
    color: var(--clr-dark-bg);
    transform: scale(0.95);
}

.action-button-disabled {
    background: var(--clr-gray-light);
    color: #ffffff50;
    cursor: not-allowed;
    transition: background 1s ease;
}

.action-button-disabled:hover {
    background: var(--clr-gray-light);
    color: #ffffff50;
    transform: scale(1);
}