/* === RADIO SELECTOR CONTENT === */
.radio-selector-row {
    width: 100%;
    /* margin-inline: auto; */
}

.radio-selector-row-label {
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-weight: 600;
}

.radio-selector-row-input {
    display: grid;
    grid-template-columns: auto auto;  /* Each item will now be as wide as its content */
    justify-content: center;  /* Centers the entire grid horizontally */
    align-items: center;
    gap: 10px;
}

.radio-selector-row-input-group {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 10px;
}

.radio-selector-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px;
    padding: 5px 8px;
    font-weight: 600;
    width: 150px;
    background-color: #ffffff68;
    color: #000;
    transition: background-color 0.3s, transform 0.2s;
    transform: scale(1);
}

.radio-selector-option:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.radio-selector-option label {
    cursor: pointer;
}

.radio-selector-option.selected {
    background-color: var(--clr-accent-main);
    color: #fff;
}

.radio-selector-option label {
    margin-left: 3px;
    margin-right: 15px;
}

.radio-selector-input {
    width: 1.5rem;
    height: 1.5rem;
    accent-color: var(--clr-accent-secondary);
    margin-block: 5px;
    margin-right: 2px;
}

.radio-selector-icon {
    user-select: none;
    margin-left: 2px;
    width: 1.5rem;
    height: 1.5rem;
    accent-color: var(--clr-accent-main);
}

.radio-selector-option.selected .material-symbols-outlined {
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48 !important;
}
/* === */
